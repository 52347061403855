import { useEffect, useRef } from 'react';
import { isEqual } from 'lodash';
import { getDataIntegrationLibraryObject } from './getDataIntegrationLibraryObject';
import { ADOBE_AUDIENCE_MANAGER_PARTNER } from '../../../../shared/config';

// data integration library instance
let dilInstance;

/**
 * Hook which send targting values to the Adobe Audience Manager
 */
export const useAdobeAudienceManager = (config) => {
  const prevConfigRef = useRef();
  useEffect(() => {
    // skip if no config or config is unchanged.
    if (!config || isEqual(prevConfigRef.current, config)) {
      return;
    }

    const targeting = config.slots?.[0]?.targeting;
    if (!targeting) {
      return;
    }

    (async () => {
      // send targeting values after Adobe Audience Manager library is loaded
      try {
        const DIL = await getDataIntegrationLibraryObject();

        // create one DIL instance per session
        if (!dilInstance) {
          // https://marketing.adobe.com/resources/help/en_US/aam/r_dil_create.html
          dilInstance = DIL.create({
            partner: ADOBE_AUDIENCE_MANAGER_PARTNER,
            uuidCookie: { name: 'aam_uuid', days: 30 },
          });
        }

        // send targeting values
        dilInstance.api.signals(targeting, 'c_').submit();
      } catch {
        // adBlocker blocks AAM library to load.
        // To prevent create noise TrackJS, we don't put log here.
      }
    })();

    // save the current configuration for comparison
    prevConfigRef.current = config;
  }, [config]);
};
